<template>
  <div id="announcement">
    <h3>Announcements</h3>
    <div class="mb-2">
      <button class="btn btn-outline-success btn-sm"
              title="Add Announcement"
              type="button"
              @click="addAnnouncement">
        <i class="fas fa-plus"></i> Announcement
      </button>
    </div>

    <!-- Announcement List -->
    <vuetable data-path="announcements"
              detail-row-component="announcement-detail"
              pagination-path="pagination"
              ref="vuetable"
              track-by="announcementId"
              :api-url="apiUrl"
              :fields="fields"
              :http-options="httpOptions"
              :css="css.table"
              :per-page="10"
              :append-params="moreParams"
              :query-params="{
                sort    : 'sort',
                page    : 'page',
                perPage : 'perPage'
              }"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:loading="showLoader"
              @vuetable:loaded="hideLoader">
      <template slot="more" slot-scope="props">
        <transition name="slide">
          <i class="fas fa-angle-down more"
            @click="showRow(props.rowData.announcementId)"
            v-if="visibleRowId !== props.rowData.announcementId"></i>

          <i class="fas fa-angle-up more"
            @click="hideRow(props.rowData.announcementId)"
            v-else></i>
        </transition>
      </template>
    </vuetable>

    <!-- Table Pagination -->
    <div class="d-flex align-items-center justify-content-end">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
      <vuetable-pagination ref="pagination"
                           @vuetable-pagination:change-page="onChangePage"
                           :css="css.pagination">
      </vuetable-pagination>
    </div>

    <!-- Modal -->
    <modals-container />
    <v-dialog/>

    <!-- Loading -->
    <loading :active.sync="isLoading"
              :is-full-page="true"
              background-color="#000"
              color="#068bcd"
              :opacity="0.5"
              :width="70"
              :height="70"
    ></loading>
  </div>
</template>

<script>
  const AddAnnouncement = () => import('./AddAnnouncement.vue');
  const AnnouncementListActions = () => import('./AnnouncementListActions.vue');
  const AnnouncementDetail = () => import('./AnnouncementDetail.vue');
  const Loading = () => import('vue-loading-overlay');
  const Vuetable = () => import('vuetable-2/src/components/Vuetable');
  const VuetablePagination =
    () => import('vuetable-2/src/components/VuetablePagination');
  const VuetablePaginationInfo =
    () => import('vuetable-2/src/components/VuetablePaginationInfo');
  
  import 'vue-loading-overlay/dist/vue-loading.css';
  import moment from 'moment-timezone';
  import TableStyle from '../table-bootstrap-css.js';
  import Vue from 'vue';
  import _ from 'lodash';

  Vue.component('announcement-detail', AnnouncementDetail);
  Vue.component('announcement-list-actions', AnnouncementListActions);

  export default {
    name : 'AnnouncementList',
    data() {
      return {
        isLoading   : false,
        adminTz     : this.$store.getters['user/user'].timezone,
        apiUrl      : process.env.VUE_APP_ROOT_API + 'api/announcement/all',
        moreParams  : {},
        css         : TableStyle,
        httpOptions : {
          headers : {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`,
          },
        },
        fields : [ {
          name       : '__slot:more',
          title      : '',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : 'announcementId',
          title      : 'ID',
          sortField  : 'announcement_id',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : 'title',
          title      : 'Title',
          sortField  : 'title',
          direction  : 'desc',
          titleClass : 'text-center',
        }, {
          name       : 'platformDisp',
          title      : 'Platform',
          sortField  : 'platformDisp',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : 'filterDisp',
          title      : 'Filter',
          sortField  : 'filterDisp',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        },{
          name       : 'link',
          title      : 'Link',
          sortField  : 'link',
          direction  : 'desc',
          titleClass : 'text-center',
        },
        // {
        //   name       : 'startSchedule',
        //   title      : 'Start Date',
        //   sortField  : 'start_date',
        //   direction  : 'desc',
        //   titleClass : 'text-center',
        // }, 
        // {
        //   name       : 'endSchedule',
        //   title      : 'End Date',
        //   sortField  : 'end_date',
        //   direction  : 'desc',
        //   titleClass : 'text-center',
        // }, 
        // {
        //   name       : 'status',
        //   title      : 'Status',
        //   sortField  : 'status',
        //   direction  : 'desc',
        //   titleClass : 'text-center',
        //   dataClass  : 'text-center',
        // }, 
        {
          name       : '__component:announcement-list-actions',
          title      : 'Actions',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        } ],
        visibleRowId : null,
      }
    },
    components : {
      Loading,
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    watch : {
      visibleRowId(newValue, oldValue) {
        if (newValue && oldValue)
          this.$refs.vuetable.hideDetailRow(oldValue);
      },
    },
    methods : {

      /**
       * Open Add Announcement Modal
       */
      addAnnouncement() {
        this.$modal.show(AddAnnouncement, {}, {
          height       : 'auto',
          adaptive     : true,
          clickToClose : false,
        }, {
          'reload-table' : (event) => {
            this.$refs.vuetable.reload();
          },
        });
      },

      /**
       * For Pagination
       * @param data
       * @return {object}
       */
      transform(data) {

        const transformed = {};

        transformed.pagination = {
          'total'         : data.total,
          'per_page'      : data.perPage,
          'current_page'  : data.currentPage,
          'last_page'     : data.lastPage,
          'next_page_url' : data.nextPageUrl,
          'pre_page_url'  : data.prevPageUrl,
          'from'          : data.from,
          'to'            : data.to,
        };

        transformed.announcements = [];

        for (let i = 0; i < data.data.length; i++) {
          const selectedTz = data.data[i].schedule_timezone;

          // Created Date conversion from UTC to Local TZ
          const createdConv = moment.utc(data.data[i].created).local()
            .format('MMM DD, YYYY hh:mm A z');
          
          // Start Date conversion from UTC to Admin TZ & Selected TZ
          const startUTC = moment.utc(data.data[i].start_date);
          const startConv = moment.utc(data.data[i].start_date).local()
            .format('MMM DD, YYYY hh:mm A z');
          const startSelectedTz = moment.tz(startUTC, selectedTz);

          // End Date conversion from UTC to Local TZ & Selected TZ
          const endUTC = moment.utc(data.data[i].end_date);
          const endConv = moment.utc(data.data[i].end_date).local()
            .format('MMM DD, YYYY hh:mm A z');
          const endSelectedTz = moment.tz(endUTC, selectedTz);

          transformed.announcements.push({
            announcementId : data.data[i].announcement_id,
            title          : data.data[i].title,
            filterDisp     : _.capitalize(data.data[i].filter),
            platformDisp   : _.capitalize(data.data[i].platform),
            startSchedule  : (data.data[i].start_date ===
              '0000-00-00 00:00:00') ? '' : startConv,
            endSchedule : (data.data[i].end_date === '0000-00-00 00:00:00') ?
              '' : endConv,
            status      : _.capitalize(data.data[i].status),
            createdDate : createdConv,
            recipient   : data.data[i].recipient,

            // For Display
            startDateDisp : (data.data[i].start_date ===
              '0000-00-00 00:00:00') ? null :
              startSelectedTz.format('MMM DD, YYYY hh:mm A z'),
            endDateDisp : (data.data[i].end_date ===
              '0000-00-00 00:00:00') ? null :
              endSelectedTz.format('MMM DD, YYYY hh:mm A z'),

            // Additional Info For Edit Announcement
            content   : data.data[i].content,
            filter    : data.data[i].filter,
            platform  : data.data[i].platform,
            timezone  : (selectedTz === "") ? null : selectedTz,
            startDate : (data.data[i].start_date ===
              '0000-00-00 00:00:00') ? null :
              startSelectedTz.format('YYYY-MM-DD'),
            startTime : (data.data[i].start_date ===
              '0000-00-00 00:00:00') ? null :
              startSelectedTz.format('HH:mm'),
            endDate : (data.data[i].end_date ===
              '0000-00-00 00:00:00') ? null :
              endSelectedTz.format('YYYY-MM-DD'),
            endTime : (data.data[i].end_date ===
              '0000-00-00 00:00:00') ? null :
              endSelectedTz.format('HH:mm'),
            link : data.data[i].link,
            mediaType : data.data[i].media_type,
            playlist: data.data[i].playlist_id,
          });
        }

        return transformed;
      },

      /**
       * Update Paginaton info
       * @param paginationData
       */
      onPaginationData(paginationData) {
        this.$refs.pagination.setPaginationData(paginationData);
        this.$refs.paginationInfo.setPaginationData(paginationData);
      },

      /**
       * Update Vuetable page
       * @param page
       */
      onChangePage(page) {
        this.$refs.vuetable.changePage(page);
      },

      /**
       * Toggle More Detail
       */
      showRow(id) {
        this.$refs.vuetable.showDetailRow(id);
        this.visibleRowId = id;
      },

      hideRow(id) {
        this.$refs.vuetable.hideDetailRow(id);
        this.visibleRowId = null;
      },

      /**
       * Show Loader
       */
      showLoader() {
        this.isLoading = true;
      },

      /**
       * Hide Loader
       */
      hideLoader() {
        this.isLoading = false;
      },

    },
    mounted() {
      this.$store.dispatch('address/getCountries');
      this.$events.$on('loading-state',
                       eventData => {
                         if (eventData)
                           this.showLoader();
                         else
                           this.hideLoader();
                       });
      this.$events.$on('reload', () => {
        this.$refs.vuetable.refresh();
      });
    },
  }
</script>

<style lang="scss" scoped>
  @import
    "../../../assets/scss/components/admin/announcement/announcement-list";
</style>