import { render, staticRenderFns } from "./AnnouncementList.vue?vue&type=template&id=bff4a650&scoped=true"
import script from "./AnnouncementList.vue?vue&type=script&lang=js"
export * from "./AnnouncementList.vue?vue&type=script&lang=js"
import style0 from "./AnnouncementList.vue?vue&type=style&index=0&id=bff4a650&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bff4a650",
  null
  
)

export default component.exports